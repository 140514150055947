@use '@angular/material' as mat;

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$mat-primary: (
    50 : var(--primary-color-50),
    100 : var(--primary-color-100),
    200 : var(--primary-color-200),
    300 : var(--primary-color-300),
    400 : var(--primary-color-400),
    500 : var(--primary-color-500),
    600 : var(--primary-color-600),
    700 : var(--primary-color-700),
    800 : var(--primary-color-800),
    900 : var(--primary-color-900),
    A100 : var(--primary-color-A100),
    A200 : var(--primary-color-A200),
    A400 : var(--primary-color-A400),
    A700 : var(--primary-color-A700),
    contrast: (
      50: var(--primary-color-contrast-50),
      100: var(--primary-color-contrast-100),
      200: var(--primary-color-contrast-200),
      300: var(--primary-color-contrast-300),
      400: var(--primary-color-contrast-400),
      500: var(--primary-color-contrast-500),
      600: var(--primary-color-contrast-600),
      700: var(--primary-color-contrast-700),
      800: var(--primary-color-contrast-800),
      900: var(--primary-color-contrast-900),
      A100: var(--primary-color-contrast-A100),
      A200: var(--primary-color-contrast-A200),
      A400: var(--primary-color-contrast-A400),
      A700: var(--primary-color-contrast-A700),
    )
);
$mat-accent: (
  50 : var(--accent-color-50),
  100 : var(--accent-color-100),
  200 : var(--accent-color-200),
  300 : var(--accent-color-300),
  400 : var(--accent-color-400),
  500 : var(--accent-color-500),
  600 : var(--accent-color-600),
  700 : var(--accent-color-700),
  800 : var(--accent-color-800),
  900 : var(--accent-color-900),
  A100 : var(--accent-color-A100),
  A200 : var(--accent-color-A200),
  A400 : var(--accent-color-A400),
  A700 : var(--accent-color-A700),
    contrast: (
      50: var(--accent-color-contrast-50),
      100: var(--accent-color-contrast-100),
      200: var(--accent-color-contrast-200),
      300: var(--accent-color-contrast-300),
      400: var(--accent-color-contrast-400),
      500: var(--accent-color-contrast-500),
      600: var(--accent-color-contrast-600),
      700: var(--accent-color-contrast-700),
      800: var(--accent-color-contrast-800),
      900: var(--accent-color-contrast-900),
      A100: var(--accent-color-contrast-A100),
      A200: var(--accent-color-contrast-A200),
      A400: var(--accent-color-contrast-A400),
      A700: var(--accent-color-contrast-A700),
    )
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$siemprebien-primary: mat.define-palette($mat-primary);
$siemprebien-accent: mat.define-palette($mat-accent);

// The warn palette is optional (defaults to red).
$siemprebien-warn: mat.define-palette(mat.$red-palette);
$siemprebien-theme: mat.define-light-theme((
  color: (
    primary: $siemprebien-primary,
    accent: $siemprebien-accent,
    warn: $siemprebien-warn,
  )
));
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".



// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
.light{
  @include mat.all-component-themes($siemprebien-theme);
}

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; top: 0px !important; }

body { margin: 0; font-family: Montserrat, "Montserrat", sans-serif; font-display: swap; }


.italicPlaceholder::placeholder {
  font-style: italic;
  font-size: 16px;
}
.underLineStyle{
  position: relative !important;
}
.underLineStyle:after {
  content: '';
  width: 30%;
  height: 2px;
  clear: left;
  position: absolute;
  left: 0;
  bottom: -5px;
}
.login-class {
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 30rem;
  min-height: 30rem;
}
@media (max-width: 768px) and (min-width: 225px ) {
  .login-class {
    min-width: 100% !important;
    min-height: 100% !important;
  }
}

.mat-sort-header-container {
  justify-content: center !important;
}
.mat-sort-header-content{
  margin-left: 18px !important;
}

.cdk-global-overlay-wrapper.super {
  position: fixed;
  z-index: 1001 !important;
}
table {
  width:100%;
}
table tr:last-child td{
  border:0
}
.mat-table
{
  background-color:transparent!important;
}
.mat-snack-bar-container{
  background-color: white !important;
}
.snack-error-white {
  color: black !important;
  font-weight: 500;
  background-color: white !important;
}
.snack-error-white button {
  color: white !important;
  background-color: var(--primary-color-300) !important;

}
.snack-error {
  color: white !important;
  background-color: var(--primary-color-300) !important;
}
.snack-error button {
  color: white !important;
  background-color: var(--primary-color-300) !important;

}
ngx-slick-carousel {
  z-index: 999 !important;
}
@media screen and (max-width: 960px) {
.mat-snack-bar-container{
  margin: 8px !important;
}}
// Mat table responsive para celular
@media screen and (max-width: 960px) {
  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left: 0px !important;
  }
  th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type{
    padding-right: 0px !important;
  }
  .mat-sort-header-arrow {
    margin: 0px  !important;
  }
  .mat-sort-header-content {
    margin-left: 0px !important;
  }
	.mat-elevation-z8 {
	  background: transparent;
	  box-shadow: none;
	}

	.mat-header-row {
	  display: none;
	}

  //Ocultado ya que generaba bugs visuales en el desplegable de datepicker (Se deja por si algún otro elemento muestra conflicto).
	// tbody {
	//   display: block;
	//   width: 100%;
	// }

	.mat-table {
	  background: transparent;
	  * {
		box-sizing: border-box;
	  }

	  .mat-row {
		display: block;
		overflow: hidden;
		height: auto;
		position: relative;
		clear: both;
		box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);

		border-radius: 3px;
		+ .mat-row {
		  margin-top: 24px;
		}
	  }

	  .mat-cell {
		display: block;
		width: 100%;
		margin: 16px 0;
		border: 0 none;
		&:first-child {
		  &:before {
			display: none;
		  }
		}
		&.m-card-sub-title {
		  margin-top: -8px;
		  padding: 0 48px 0 16px;

		}
	  }

	  .has_label_on_mobile {
		padding: 0 48px 0 16px;
		display: block;
		width: 100%;
		padding: 0 16px;
		margin: 16px 0;
		border: 0 none;
		&:before {
		  content: attr(data-label);
		  display: inline;
		}
	  }

	  .mat-column-star {
		width: auto;
		padding: 8px 0 0 !important;
		margin: 0;
		position: absolute;
		top: 0;
		right: 0;
		&:before {
		  display: none;
		}
	  }
	}

	.mat-paginator {
	  margin-top: 24px;
	}

  }

  a.disabled {
    pointer-events: none;
    cursor: default;
  }

  .ngx-gallery-thumbnails {
    margin-top: 1rem/* 16px */ !important;
    margin-bottom: 1rem/* 16px */ !important;
    justify-content: center !important;
    display: flex !important;
    flex-wrap: wrap !important;
    position: relative !important;
    transform: none !important;
    transition-property: none !important;
   }
   .ngx-gallery-thumbnails-wrapper{
    position: static !important;
    overflow: visible !important;
   }
   ngx-gallery-image{
    position: relative !important;
    height: 100% !important;
    display: flex !important;
   }
   .ngx-gallery-image-wrapper{
    display: flex !important;
    flex: 1 1 0% !important;
    justify-content: center !important;
    width: 100% !important;
    height: 20rem/* 320px */ !important;
    position: relative !important;
    left: auto !important;
   }
   .ngx-gallery-image{
    display: flex !important;
    flex-grow: 0 !important;
    left: auto !important;
    width: 50% !important;
    @media (min-width: 225px) and (max-width: 768px) {
          width: 100% !important;
  }
   }
   ngx-gallery-thumbnails{
    display: flex !important;
    flex-grow: 1 !important;
    margin-top: 1rem/* 16px */ !important;
    height: 100% !important;
   }

   .ngx-gallery-thumbnail{
    margin: 0.5rem/* 8px */ !important;
    width: 8rem/* 128px */ !important;
    height: 6rem/* 96px */ !important;
    top: 0px !important;
    position: static !important;
    left: auto !important;
    &:hover {
      border-width: 1px !important;
      --tw-border-opacity: 1 !important;
      border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
    }
   }
   .ngx-gallery-thumbnail.ngx-gallery-active {
    border-width: 1px !important;
    border-color: var(--primary-color-500) !important;
   }
   .underLineStyle.primary:after {
    background-color: var(--accent-color-500) !important;
   }
   .underLineStyle.accent:after {
    background-color: var(--primary-color-500) !important;
   }
   input:-webkit-autofill,
   input:-webkit-autofill:hover,
   input:-webkit-autofill:focus,
   input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px theme('colors.accent.100') inset !important;
    font-size: 1rem/* 16px */ !important;
    line-height: 1.5rem/* 24px */ !important;
   }
button{
  outline: none !important;
}
.modalFull{
  width: 100% !important;
  height: 100% !important;
  max-width: none !important;
}
.modalMap{
  width: 70% !important;
  height: 80% !important;
  max-width: none !important;
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: var(--primary-color-100);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--primary-color-500);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
}
.modalMedium{
  width: 60% !important;
  height: 50% !important;
  max-width: none !important;
  /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: var(--primary-color-100);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--primary-color-500);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
}
.modalSmall{
  width: 30% !important;
  max-width: none !important;
  /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: var(--primary-color-100);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--primary-color-500);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
}
.modalFullMobile{
  @media (min-width: 225px) and (max-width: 768px) {
        height: 100% !important;
        width: 100% !important;
        max-width: none !important;
}
}
.modalAddressMobile{
  width: 50% !important;
  height: 60% !important;
  @media (min-width: 225px) and (max-width: 768px) {
    height: 100% !important;
    width: 100% !important;
    max-width: none !important;
}
}
.slick-dots {
  bottom: 0px !important;
}
.slick-dots li.slick-active button:before{
  color: var(--primary-color-900) !important;
  font-size: 1rem/* 16px */ !important;
  line-height: 1.5rem/* 24px */ !important;
}
.slick-dots li button:before{
  color: var(--primary-color-900) !important;
  font-size: 1rem/* 16px */ !important;
  line-height: 1.5rem/* 24px */ !important;
}

.pasos .mat-progress-bar-fill::after {
  background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
  --tw-gradient-from: var(--primary-color-300) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  --tw-gradient-to: var(--primary-color-700) !important;
}

.pasos .mat-progress-bar-buffer {
  background-color: var(--primary-color-200) !important;
}

.pasos.mat-progress-bar {
  height: 0.75rem/* 12px */ !important;
  border-radius: 0.5rem/* 8px */ !important;
}

.scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
}

.app-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
